<template>
  <div>
    <Toast position="top-right" />
    <ModalAgenteSistema @atualizarListagemDeCampanha="pegarCampanhas" />
    <ModalCadastroCallback
      @aoCadastrarCallback="aoCadastrarCallback"
      v-if="navSelecionada == 2"
    />
    <div v-if="mostrarPainel" class="container-dashboard">
      <painel-informativo />
    </div>

    <div class="card">
      <div class="card-header" style="padding: 0 !important">
        <!-- <h3 class="card-title">Title</h3> -->
        <div class="card-toolbar">
          <div
            class="d-flex align-items-end justify-content-between h-100 w-100 pr-5"
          >
            <b-nav
              class="h-100 d-flex justify-content-center align-items-center"
              tabs
              fill
              style="margin-bottom: -1px"
            >
              <b-nav-item :active="navSelecionada == 0" @click="mudarNav(0)"
                >Campanhas</b-nav-item
              >
              <b-nav-item
                :active="navSelecionada == 1"
                @click="mudarNav(1)"
                v-if="temRecursoChatbot"
                >Consultas</b-nav-item
              >
              <b-nav-item
                :active="navSelecionada == 2"
                @click="mudarNav(2)"
                v-if="temRecursoChatbot"
                >Callbacks</b-nav-item
              >
              <b-nav-item
                v-if="recursoAtivoConta('WHATSAPP_OFICIAL')"
                :active="navSelecionada == 3"
                @click="mudarNav(3)"
                >Template WhatsApp</b-nav-item
              >
            </b-nav>
            <div
              class="d-flex align-items-center py-5"
              style="justify-content: flex-end"
            >
              <b-button
                v-if="isAdmin || hasPermission('gerenciar-campanha')"
                variant="success"
                class="b-button-custom mr-2"
                @click="clickBotaoCadastro"
              >
                {{ labelBotao[navSelecionada] }}
              </b-button>

              <b-button
                class="mr-2"
                variant="outline-secondary"
                title="Limpar Filtros"
                @click="limparFiltros"
                v-if="filtrosAtivos && [0, 3].includes(navSelecionada)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-arrows-spin"
                  style="font-size: 1.3rem; display: flex"
                />
              </b-button>

              <b-button
                v-if="navSelecionada == 0 || navSelecionada == 3"
                variant="outline-secondary"
                class="b-button-custom mr-2"
                v-b-toggle.filtrosCollapse
                @click="filtrosAtivos = !filtrosAtivos"
                id="botaoFiltrosCampanha"
              >
                <i class="fas fa-filter"></i> Filtros
              </b-button>

              <BotaoModalAgenteSistemaVue
                class="ml-2"
                v-if="navSelecionada == 0"
              />
            </div>
          </div>

          <div v-show="navSelecionada == 0">
            <FiltrosCampanha
              ref="FiltrosDeCampanha"
              @filtrarCampanhas="filtrarCampanhas"
            />
          </div>

          <div v-show="navSelecionada == 3">
            <FiltrosTemplate
              ref="FiltrosDeTemplate"
              v-model="filtrosTemplate"
            />
          </div>
        </div>
      </div>

      <div class="card-body p-0 px-4">
        <div class="aut-area-exibir">
          <IndexCampanha
            :campanhas="campanhasFiltradas"
            :filtrosCampanha="filtrosCampanha"
            class="aut-camp-area"
            v-if="navSelecionada == 0"
            @pegarCampanhas="pegarCampanhas"
            @editarCampanha="editarCampanha"
          />
          <IndexConsulta class="aut-camp-area" v-if="navSelecionada == 1" />
          <IndexCallbacks
            class="aut-camp-area"
            v-if="navSelecionada == 2 && callbacksAtivas"
          />
          <IndexTemplate
            class="aut-camp-area"
            ref="indexTemplate"
            :filtros="filtrosTemplate"
            :contas="subcontas"
            v-show="navSelecionada == 3"
          />
        </div>
      </div>
    </div>

    <ModalCadastroCampanha
      :modal_name="nomeModalCadastroCampanha"
      :modelo_campanha_id="modeloCampanhaIdParaEdicao"
      :context_functions="contextFunctions"
      @toggleModalCadastrarCampanha="toggleModalCadastrarCampanha"
      @updateCampanhas="pegarCampanhas"
      @editCampaignRetrocompatibility="editarCampanha"
      ref="modalCampaignCreation"
      v-if="
        mostrarCampanhaEdicao &&
        (isAdmin || hasPermission('gerenciar-campanha'))
      "
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import API_LINKS from "./api.links";
import cronstrue from "cronstrue/i18n";
import parser from "cron-parser";
import moment from "moment";

export default {
  components: {
    IndexCampanha: () => import("./campanha/Index.vue"),
    PainelInformativo: () => import("./components/PainelInformativo.vue"),
    Toast: () => import("primevue/toast"),
    BotaoModalAgenteSistemaVue: () =>
      import("./components/BotaoModalAgenteSistema.vue"),
    ModalAgenteSistema: () => import("./components/ModalAgenteSistema"),
    IndexConsulta: () => import("./fluxo/consultas/Index.vue"),
    IndexCallbacks: () => import("./fluxo/callbacks/Index.vue"),
    ModalCadastroCallback: () =>
      import("./fluxo/components/ModalCadastroCallback"),
    FiltrosCampanha: () => import("./campanha/components/FiltrosCampanha.vue"),
    FiltrosTemplate: () => import("./campanha/components/FiltrosTemplate.vue"),
    ModalCadastroCampanha: () => import("./campanha/ModalCadastroCampanha.vue"),
    IndexTemplate: () => import("./template-whatsapp/Index.vue")
  },
  data() {
    return {
      mostrarCampanhaEdicao: true,
      modeloCampanhaIdParaEdicao: null,
      recursosDoUsuario: [],
      filtrosAtivos: false,
      callbacksAtivas: true,
      menusAtivos: true,
      menusChatbot: [],
      mostrarPainel: false,
      subcontas: [],
      conta: -1,
      navSelecionada: 0,
      showOverlay: true,
      campanhas: [],
      filtrosCampanha: {},
      filtrosTemplate: {},
      labelBotao: [
        "Nova Campanha",
        "Nova Consulta",
        "Nova Callback",
        "Novo Template"
      ],
      aba: ["campanha", "consulta", "callbacks", "template"],
      licenca: {
        windows: {
          carregandoLinkWindows: 0,
          linkDownload: {
            link: "https://wellon.digital/wp-content/uploads/2021/09/wellon_degrade-1536x569.png",
            tempoVida: 20
          }
        },
        linux: {
          carregandoLinkLinux: 0
        }
      },
      contextFunctions: null
    };
  },
  methods: {
    async toggleModalCadastrarCampanha() {
      this.modeloCampanhaIdParaEdicao = null;
      await new Promise((resolve) => setTimeout(resolve, 500));
      this.mostrarCampanhaEdicao = false;
      await new Promise((resolve) => setTimeout(resolve, 50));
      this.mostrarCampanhaEdicao = true;
    },
    async editarCampanha(campanha) {
      this.mostrarCampanhaEdicao = false;
      await new Promise((resolve) => setTimeout(resolve, 50));
      this.mostrarCampanhaEdicao = true;
      await new Promise((resolve) => setTimeout(resolve, 50));
      this.modeloCampanhaIdParaEdicao = campanha.modelo_campanha_id;
      this.$refs.modalCampaignCreation.openTransition();
    },
    async getContextFunctions() {
      const response = await ApiService.get(
        API_LINKS.campanha + "/funcao-contexto",
        "",
        false
      );
      this.contextFunctions = response.data.funcoes;
      return response;
    },
    async listarRecursosAtivos() {
      const res = await ApiService.get(API_LINKS.recursosAtivos);

      if (res.data.status !== "success") return;
      this.recursosDoUsuario = res.data.data;

      return res;
    },
    limparFiltros() {
      this.$refs.FiltrosDeCampanha.limparFiltros();
      this.$refs.FiltrosDeTemplate.setarFiltroDeReset();
    },
    mudarNav(nav) {
      this.$router.replace({
        path: this.$route.path,
        query: { navSelecionada: nav }
      });
      this.navSelecionada = nav;
    },
    filtrarCampanhas(filtros) {
      this.filtrosCampanha = filtros;
    },
    filtrarTemplates(filtros) {
      this.filtrosTemplate = filtros;
    },
    aoEditarMenu() {
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Menu salvo com sucesso",
        life: 3000
      });
    },
    mostrarModalCadastroCallback() {
      this.$modal.show("modal-cadastro-callback");
    },
    clickBotaoCadastro() {
      if (this.navSelecionada == 0) {
        this.$refs.modalCampaignCreation.registerNewCampaign();
      } else if (this.navSelecionada == 2) {
        this.mostrarModalCadastroCallback();
      } else {
        this.$router.push({
          path: "/painel/automacao/cadastro-".concat(
            this.aba[this.navSelecionada]
          )
        });
      }
    },
    toastEmConstrucao() {
      this.$toast.add({
        severity: "info",
        summary: "Em construção",
        detail: "Em breve estará disponível",
        life: 3000
      });
    },
    aoCadastrarMenuItem() {
      this.menusAtivos = false;
      setTimeout(() => {
        this.menusAtivos = true;
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Menu cadastrado com sucesso",
          life: 3000
        });

        this.$modal.hide("modal-cadastrar-menu-chatbot");
      }, 200);
    },
    aoCadastrarCallback() {
      this.callbacksAtivas = false;
      setTimeout(() => {
        this.callbacksAtivas = true;
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Callback cadastrado com sucesso",
          life: 3000
        });

        this.$modal.hide("modal-cadastro-callback");
      }, 200);
    },
    async pegarCampanhas() {
      const res = await ApiService.get(API_LINKS.campanha).then((response) => {
        this.campanhas = response.data.campanhas
          .map((campanha) => {
            const canaisAtivos = [];
            campanha.CANAIS.map((canal) => {
              if (canal.cac_ativo) {
                canaisAtivos.push(canal.canal_id == 4 ? "bi" : canal.can_nome);
              }
            });

            campanha.canais_label = canaisAtivos.sort((a, b) => {
              return a.length - b.length;
            });

            campanha.CRONS.map((cron) => {
              campanha.cron_texto = cronstrue.toString(cron.mdc_cron, {
                locale: "pt_BR",
                use24HourTimeFormat: true
              });

              cron.proxima_execucao = cron.mdc_ativo
                ? this.obterProximaExecucao(cron)
                : null;
            });
            campanha.automacao_ativa = campanha.CRONS.some(
              (element) => element.mdc_ativo == true
            );
            campanha.proxima_execucao = campanha.CRONS.sort(function (a, b) {
              if (a.proxima_execucao < b.proxima_execucao) return -1;
              if (a.proxima_execucao > b.proxima_execucao) return 1;
              return 0;
            })[0];
            return campanha;
          })
          .sort((a, b) => b.automacao_ativa - a.automacao_ativa);
      });

      return res;
    },
    obterProximaExecucao(cron) {
      const hoje = moment();
      if (cron.mdc_data_inicio && cron.mdc_data_fim) {
        const dataInicio = moment(cron.mdc_data_inicio);
        const dataFim = moment(cron.mdc_data_fim);

        const options = {
          currentDate: hoje.isAfter(dataInicio)
            ? hoje.toDate()
            : dataInicio.toDate(),
          endDate: dataFim.toDate(),
          tz: "America/Fortaleza",
          iterator: true
        };

        const interval = parser.parseExpression(cron.mdc_cron, options);
        return interval.hasNext() ? interval.next().value.toDate() : null;
      } else if (cron.mdc_data_inicio) {
        const dataInicio = moment(cron.mdc_data_inicio).subtract(30, "seconds");
        const options = {
          currentDate: hoje.isAfter(dataInicio)
            ? hoje.toDate()
            : dataInicio.toDate(),
          tz: "America/Fortaleza",
          iterator: true
        };

        const interval = parser.parseExpression(cron.mdc_cron, options);
        return interval.hasNext() ? interval.next().value.toDate() : null;
      } else if (cron.mdc_data_fim) {
        const dataFim = moment(cron.mdc_data_fim);
        const options = {
          currentDate: hoje.toDate(),
          endDate: dataFim.toDate(),
          tz: "America/Fortaleza",
          iterator: true
        };

        const interval = parser.parseExpression(cron.mdc_cron, options);
        return interval.hasNext() ? interval.next().value.toDate() : null;
      }

      const options = {
        tz: "America/Fortaleza",
        iterator: true
      };
      const interval = parser.parseExpression(cron.mdc_cron, options);
      return interval.hasNext() ? interval.next().value.toDate() : null;
    },
    formatarTrailingZeros(numero) {
      return numero < 10 ? "0" + numero : numero;
    },
    async pegarContaSubContas() {
      const subcontas = await ApiService.get(
        "/administrativo/conta",
        "",
        false
      );
      this.subcontas = subcontas.data.subContas.map((v) => {
        return {
          name: v.con_login,
          value: v.conta_id,
          identificacao: v.con_nome_identificacao
        };
      });
      const contaPrincipalId = subcontas.data?.contaPrincipal?.conta_id;
      this.subcontas.unshift({
        value: contaPrincipalId,
        name: "Conta Principal"
      });
      this.conta = contaPrincipalId;
      return subcontas;
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Campanhas" }]);
    await this.pegarCampanhas();
    await this.listarRecursosAtivos();
    await this.getContextFunctions();
    await this.pegarContaSubContas();

    if (this.recursoAtivoConta("WHATSAPP_OFICIAL")) {
      this.$refs["indexTemplate"]?.carregarTemplates();
    }

    if (this.$route.params.navSelecionada) {
      setTimeout(() => {
        this.navSelecionada = this.$route.params.navSelecionada | 0;
      }, 200);
    } else if (this.$route.query.navSelecionada) {
      setTimeout(() => {
        this.mudarNav(this.$route.query.navSelecionada | 0);
      }, 200);
    }
    if (
      this.$route.params.toast != "undefined" &&
      this.$route.params.toast != null
    ) {
      setTimeout(() => {
        this.$toast.removeAllGroups();
        this.$toast.add(this.$route.params.toast);
      }, 2000);
    }
    if (this.$route.params.cadastrarAgente) {
      setTimeout(() => {
        this.$refs.agenteRef.mostrarModal();
      }, 1000);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const filtros_ativos_na_conta = localStorage.getItem(
      "filtros_ativos_" + this.currentUserPersonalInfo.conta
    );

    if (this.$route.query.campanhaTipo) {
      const botaoFiltro = document.getElementById("botaoFiltrosCampanha");
      botaoFiltro.click();
    } else if (filtros_ativos_na_conta == "true") {
      const botaoFiltro = document.getElementById("botaoFiltrosCampanha");
      botaoFiltro.click();
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.name == "automacao") {
      if (
        to.query?.navSelecionada != from.query?.navSelecionada &&
        !to.query.campanhaTipo
      ) {
        this.navSelecionada = to.query.navSelecionada | 0;
      }
      if (from.query.campanhaTipo && !to.query.campanhaTipo) {
        next();
        window.location.reload();
      } else if (to.query.campanhaTipo != from.query.campanhaTipo) {
        if (this.navSelecionada != 0) {
          this.navSelecionada = 0;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (!this.filtrosAtivos) {
          const botaoFiltro = document.getElementById("botaoFiltrosCampanha");
          botaoFiltro.click();
        }

        this.$refs.FiltrosDeCampanha.filtroCampanhaObj.tiposDeCampanha =
          this.$refs.FiltrosDeCampanha.tiposDeCampanha.filter(
            (t) => t.tipo_campanha_id == to.query.campanhaTipo
          );
        this.$refs.FiltrosDeCampanha.filtroCampanhaObj.tiposDeCampanha.push({
          code: null,
          text: "Sem tipo"
        });
      }
      next();
    }
  },
  beforeDestroy() {
    localStorage.setItem(
      "filtros_ativos_" + this.currentUserPersonalInfo.conta,
      this.filtrosAtivos
    );
  },
  computed: {
    ...mapGetters([
      "isAdmin",
      "hasPermission",
      "recursoAtivoConta",
      "currentUserPersonalInfo"
    ]),
    nomeModalCadastroCampanha() {
      return this.modeloCampanhaIdParaEdicao
        ? "edicao-" + this.modeloCampanhaIdParaEdicao
        : "criar";
    },
    temRecursoChatbot() {
      return this.recursosDoUsuario?.find((recurso) => {
        return recurso.recurso_id == 4;
      });
    },
    campanhasFiltradas() {
      if (!this.filtrosAtivos) return this.campanhas;
      const campanhas_filtradas = this.campanhas.filter((campanha) => {
        let filtro = true;

        // filtros para nome da campanha
        if (this.filtrosCampanha.nome) {
          filtro =
            filtro &&
            campanha.mdc_nome
              .toLowerCase()
              .includes(this.filtrosCampanha.nome.toLowerCase());
        }

        // filtros para canais de envio
        const canaisId = this.filtrosCampanha?.canais?.map(
          (canal) => canal.canal_id
        );
        if (
          this.filtrosCampanha.canais &&
          this.filtrosCampanha.canais.length > 0
        ) {
          filtro =
            filtro &&
            campanha.CANAIS.some((canal) => canaisId.includes(canal.canal_id));
        }

        // filtros para modelos de campanha
        const modelosIds = this.filtrosCampanha?.modelos?.map(
          (modelo) => modelo.modelo_id
        );
        if (
          this.filtrosCampanha.modelos &&
          this.filtrosCampanha.modelos.length > 0
        ) {
          filtro = filtro && modelosIds.includes(campanha.modelo_id);
        }

        // filtros para subcontas e conta principal
        const subcontasIds = this.filtrosCampanha?.subcontas?.map(
          (subconta) => subconta.conta_id
        );

        let contemPrincipal = this.filtrosCampanha?.subcontas?.some(
          (i) => this.filtrosCampanha?.contaPrincipal?.code == i.code
        );
        if (contemPrincipal) {
          subcontasIds.push(null); // Exibir campanhas com contas igual a null quando a principal estiver selecionada
        }

        if (
          this.filtrosCampanha.subcontas &&
          this.filtrosCampanha.subcontas.length > 0
        ) {
          filtro =
            filtro && subcontasIds.includes(campanha.mdc_preferencial_conta_id);
        }

        // filtro para tipo de campanha
        const tiposDeCampanhaIds = this.filtrosCampanha?.tiposDeCampanha?.map(
          (tipo) => tipo.code
        );

        if (
          this.filtrosCampanha.tiposDeCampanha &&
          this.filtrosCampanha.tiposDeCampanha.length > 0
        ) {
          filtro =
            filtro && tiposDeCampanhaIds.includes(campanha.tipo_campanha_id);
        }

        return filtro;
      });

      this.$toast.removeAllGroups();
      return campanhas_filtradas;
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&display=swap");

.b-button-custom {
  width: 142px;
}

.aut-body {
  border-radius: 5px;
  background-color: #fff;
}

.aut-area-exibir {
  display: flex;
  height: 100%;
  width: 100%;
}

.aut-header {
  height: 70px;
  width: 100%;
}

.aut-camp-area {
  width: 100%;
  min-height: 500px;
}

.container-dashboard {
  border-radius: 15px;
  background-color: #fff;
  padding: 20px 10px 20px 10px;
  margin-bottom: 15px;
}

.campo-estatistica {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.dropdown-form {
  padding: 3px 0px;
  width: 170px;
  height: 45px;
}

.dropdown-form:hover {
  background-color: rgb(247, 247, 247);
}
/* .icone-dropdown {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.icone-dropdown:hover {
  transform: scale(1.2);
} */
.button-container {
  border: 1px solid #e4e6ef;
  outline: none;
  color: #e3e4ed;
  background-color: transparent;
  border-radius: 0.42rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 38px;
}
.button-container.btn i {
  padding: 0 !important;
}
</style>
